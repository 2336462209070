import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';

export interface CompanySelectProps extends BaseFormControlProps {
    onCompanyChange?: (companyId: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function CompanySelect({ name, control, required, md = 4, xs = 12, onCompanyChange, isAddAll, readOnly, defaultValue }: CompanySelectProps) {
    const { currentCustomer } = useCustomer();
    const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadCompany();
    }, [currentCustomer]);

    useEffect(() => {
        loadCompany();
    }, []);

    const loadCompany = () => {
        const options = currentCustomer?.companies.toSelectOptions('name', 'id') ?? [];
        setCompanyOptions(options);
    };

    return (
        <SelectField
            defaultValue={defaultValue}
            readOnly={readOnly}
            label={t(Translations.form.site.name)}
            options={companyOptions || []}
            name={name ?? 'companyId'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            xs={xs}
            onValueChange={(e) => {
                onCompanyChange && onCompanyChange(e);
            }}
        />
    );
}
