import { AreaSelect } from 'components/Common';
import { DepartmentSelect } from 'components/Common/DepartmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { InputField, SelectField, SelectOption } from 'components/FormControl';
import { PPRTaskType } from 'enum/ppr';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';

export const SKPIObjectTypeSelect = ({
    companyId,
    md,
    xs,
    control,
    onTargetObjectNameChanged,
    defaultTaskType,
    requiredObjectId = true,
}: {
    md: 2 | 3 | 4 | 6 | 10 | 12;
    xs?: 2 | 3 | 4 | 6 | 10 | 12;
    companyId: string;
    control: Control<any>;
    onTargetObjectNameChanged: (nane: string) => void;
    defaultTaskType?: PPRTaskType;
    requiredObjectId?: boolean; //true trong page create, false trong page list
}) => {
    const [taskTypeOptions, setTaskTypeOptions] = useState<SelectOption[]>([
        { label: lang.text(Translations.form.area.name), value: PPRTaskType.AREA },
        { label: lang.text(Translations.common.employee), value: PPRTaskType.USER },
        { label: lang.text(Translations.form.department.name), value: PPRTaskType.DEPARTMENT },
    ]);

    const [targetObjectName, setTargetObjectName] = useState<string>(''); // [1
    const [pprTaskType, setPprTaskType] = useState<PPRTaskType>(PPRTaskType.AREA);
    useEffect(() => {
        onTargetObjectNameChanged(targetObjectName);
    }, [targetObjectName]);

    return (
        <Fragment>
            <SelectField
                label={lang.text(Translations.wpr.ppr.type)}
                control={control}
                name="taskType"
                options={taskTypeOptions}
                md={md}
                xs={xs}
                defaultValue={defaultTaskType ?? PPRTaskType.AREA}
                onValueChange={(value: PPRTaskType) => {
                    setPprTaskType(value);
                }}
            />
            {pprTaskType === PPRTaskType.USER && (
                <UserSelectField
                    onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                        setTargetObjectName(value?.label ?? '');
                    }}
                    readOnly={false}
                    label={lang.text(Translations.common.employee)}
                    name="targetObjectId"
                    control={control}
                    required={requiredObjectId}
                    isMutiple={false}
                    md={md}
                    xs={xs}
                />
            )}
            {pprTaskType === PPRTaskType.AREA && (
                <AreaSelect
                    onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                        setTargetObjectName(value?.label ?? '');
                    }}
                    md={md}
                    xs={xs}
                    name="targetObjectId"
                    control={control}
                    required={requiredObjectId}
                    companyId={companyId}
                    readOnly={false}
                />
            )}

            {pprTaskType === PPRTaskType.DEPARTMENT && (
                <DepartmentSelect
                    onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                        setTargetObjectName(value?.label ?? '');
                    }}
                    md={4}
                    xs={xs}
                    name="targetObjectId"
                    control={control}
                    required={requiredObjectId}
                    companyId={companyId}
                    readOnly={false}
                />
            )}
            <InputField md={md} xs={xs} name="targetObjectName" className="d-none" control={control} readOnly={true} defaultValue={targetObjectName} />
        </Fragment>
    );
};
