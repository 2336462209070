import { yupResolver } from '@hookform/resolvers/yup';
import { KPITemplateCreateRequest } from 'api/v2/kpi/model/kpiTemplate.model';
import { InputField, TextAreaField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import KPITypeSelect from '../kpiType/kpiTypeSelect.component';

interface KPITemplateFormProps extends ModalHookComponentProps {
    defaultTemplate?: KPITemplateCreateRequest;
    companyId?: string;
}
const KPITemplateForm = ({ onClose, onCompleted, defaultTemplate, companyId }: KPITemplateFormProps) => {
    useEffect(() => {
        defaultTemplate && reset({ ...defaultTemplate });
    }, [defaultTemplate]);

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm<KPITemplateCreateRequest>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required().max(250),
                description: yup.string().max(500),
                unit: yup.string().required().max(50).min(1),
                maximum: yup.number().required().min(0),
                // no: yup.number().required().min(0),
                typeId: yup.string().required(),
            })
        ),
    });

    // name: string;
    // description: string;
    // unit: string;
    // maximum: string;
    // no: number;
    // typeId: string;
    const onSubmit = async () => {
        var form = getValues();

        let data: KPITemplateCreateRequest = {
            ...form,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <KPITypeSelect disable={defaultTemplate ? true : false} control={control} companyId={companyId} defaultValue={defaultTemplate?.typeId} name="typeId" md={12} required={true} />
                <InputField name={'name'} control={control} defaultValue={defaultTemplate?.name} label={lang.text(Translations.common.name)} required={true} md={4} xs={12} />
                <InputField name={'unit'} control={control} defaultValue={defaultTemplate?.unit} label={lang.text(Translations.kpis.unit)} required={true} md={4} xs={12} />
                <InputField name={'maximum'} control={control} defaultValue={defaultTemplate?.maximum} label={lang.text(Translations.kpis.standard)} required={true} type="number" md={4} xs={12} />
                <TextAreaField name={'description'} control={control} defaultValue={defaultTemplate?.description} label={lang.text(Translations.common.description)} md={12} xs={12} />
            </div>
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default KPITemplateForm;
