import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { SECReportFilterRequest, SECReportResponse } from 'api/v2/sec/model/register.model';
import secReportApi from 'api/v2/sec/secReportApi';
import { DownloadButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { SelectField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { PaginationTable } from 'components/shared/PaginationTable';
import { SECCheckInOutType, SECRegisterType } from 'enum/sec';
import { formatDateTime } from 'helper';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import SECGateSelect from '../gate/secGateSelect.component';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SECReportPage = () => {
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);
    const [secRegisterType, setSECRegisterType] = useState<SECRegisterType>(SECRegisterType.PERSON);

    const cols = useMemo(
        () => [
            {
                field: 'objectCode',
                title: lang.text(secRegisterType === SECRegisterType.EQUIPMENT ? Translations.equipment.code : Translations.sec.dkshNumber),
            },
            {
                field: 'objectName',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'organizationUnitName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'gateName',
                title: lang.text(Translations.sec.gate),
            },
            {
                field: 'contractorName',
                title: lang.text(Translations.contractor),
            },
            {
                field: 'checkInOutType',
                title: lang.text(Translations.common.reportType),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'isApproved',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
        ],
        []
    );

    var navigate = useNavigate();
    const [paginationData, setPaginationData] = useState<PaginationBase<SECReportResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [areaId, setAreaId] = useState<string | null | undefined>(null);
    const [organizationId, setOrganizationId] = useState<string | null | undefined>(null);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();

    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const downloadFile = () => {
        var form = getValues();
        secReportApi
            .downloadReport({
                organizationId: organizationId,
                contractorId: form.contactorId,
                gateId: form.gateId,
                registerType: secRegisterType,
                fromDate: form.fromDate,
                toDate: form.toDate,
                organizationType: areaId ? 'AREA' : 'COMPANY',
                rowsPerPage: 10000,
                currentPage: 1,
                secCheckInOutType: form.secCheckInOutType,
            })
            .then(() => {})
            .catch(() => {});
    };

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
            organizationId: areaId ?? companyId,
            organizationType: areaId ? 'AREA' : 'COMPANY',
        } as SECReportFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        secReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                // isApproved: n.isApproved ? 'APPROVED' : 'REJECTED',
                                isApproved: <Badge bg={n.isApproved ? 'success' : 'danger'}>{n.isApproved ? 'APPROVED' : 'REJECTED'}</Badge>,
                                // reportType: <Badge bg={SORReportHelper.getReportTypeColor(n.isSafe)}>{SORReportHelper.getReportTypeText(n.isSafe)}</Badge>,
                                action: <ActionButtons2 id={n.id} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row">
                                <CompanySelect
                                    name="companyId"
                                    control={control}
                                    required={false}
                                    disable={false}
                                    readOnly={false}
                                    onCompanyChange={(id) => {
                                        setCompanyId(id);
                                        setOrganizationId(id);
                                    }}
                                />
                                <AreaSelect
                                    name="areaId"
                                    control={control}
                                    required={false}
                                    onAreaChange={(areaId) => {
                                        setAreaId(areaId);
                                        setOrganizationId(areaId);
                                    }}
                                    companyId={companyId}
                                    readOnly={false}
                                />
                                <ContractorSelect name="contractorId" control={control} required={false} readOnly={false} />
                                <SECGateSelect readOnly={false} name="gateId" control={control} required={false} organizationId={organizationId ?? ''} />
                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={4} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                                <SelectField
                                    label={lang.text(Translations.common.reportType)}
                                    options={[
                                        {
                                            label: lang.text(Translations.sec.checkIn),
                                            value: SECCheckInOutType.CHECK_IN,
                                        },
                                        {
                                            label: lang.text(Translations.sec.checkOut),
                                            value: SECCheckInOutType.CHECK_OUT,
                                        },
                                    ]}
                                    name={'secCheckInOutType'}
                                    control={control}
                                    required={false}
                                    isMutiple={false}
                                    md={4}
                                    xs={12}
                                    isAddAll={false}
                                />
                                <div className="col-md-6 m-auto d-flex justify-content-end">
                                    <DownloadButton onClick={downloadFile} />
                                    <FilterButton type="submit" className="mx-2 " />
                                </div>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
            </div>
            <PaginationTable
                onSearch={handleFetchData}
                onPaginate={handleFetchData}
                onRowClick={(item) => {
                    setCurrentDetailId(item.id);
                }}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
        </Fragment>
    );
};

export default SECReportPage;
