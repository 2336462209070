import { SECRegisterType } from 'enum/sec';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserRegisterPage from './userRegister';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SECRegisterPage = () => {
    const [key, setKey] = useState<'equipment' | 'user'>('user');
    return (
        <>
            <Tabs activeKey={key} onSelect={(k) => setKey(k === 'user' ? 'user' : 'equipment')} className="mb-3">
                <Tab eventKey="user" title={'Người dùng'}>
                    {key === 'user' && <UserRegisterPage registerType={SECRegisterType.PERSON} />}
                </Tab>
                <Tab eventKey="equipment" title="Thiết bị">
                    {key === 'equipment' && <UserRegisterPage registerType={SECRegisterType.EQUIPMENT} />}
                </Tab>
            </Tabs>
        </>
    );
};

export default SECRegisterPage;
