import Card from 'components/shared/Card';
import { Fragment, useMemo, useState } from 'react';
import { ActionButtons2 } from '../../../components/shared/ActionButtons';

import { IIRCategoryType } from 'api/enums/IIR';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import iirCategoryApi from 'api/v2/iir/iirCategoryApi';
import { IIRCategoryRequest, IIRCategoryResponse } from 'api/v2/iir/model/iirCategory.model';
import { AddButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import useModalHook from 'components/ModalHook';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Badge, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import IIRCategoryForm from './component/iirCategoryForm.component';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const IIRCategoryBasePage = ({ type }: { type: IIRCategoryType }) => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { userPms } = useAuth();
    // useEffect(() => {
    //     handleFetchData(defaultPaginationRequest);
    // }, []);

    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'companies',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var comid = getValues().companyId;
        iirCategoryApi
            .getPagination({
                ...pagination,
                type,
                companyId: comid?.hasValue() ? comid : null,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                companies:
                                    n.companies.map((n) => (
                                        <Badge bg="success" className="mx-1">
                                            {n.name}{' '}
                                        </Badge>
                                    )) ?? [],
                                action: <ActionButtons2 id={n.id} editPms={userPms.iir.admin} deletePms={userPms.iir.admin} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        var request: any = await openModal({
            title: lang.text(type == IIRCategoryType.IncidentType ? Translations.iir.incidentType : Translations.iir.incidentLevel),
            body: <IIRCategoryForm type={type} name="" companyIds={[]} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            iirCategoryApi
                .create(request)
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: IIRCategoryResponse) => {
        var request: IIRCategoryRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <IIRCategoryForm type={type} name={data.name} companyIds={data.companies.map((x) => x.id)} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            iirCategoryApi
                .update({ ...request, id: data.id })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        iirCategoryApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    const { control, getValues } = useForm();
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="row">
                        <CompanySelect
                            isAddAll={false}
                            md={12}
                            xs={12}
                            name="companyId"
                            control={control}
                            required={false}
                            disable={false}
                            readOnly={false}
                            onCompanyChange={() => handleFetchData(defaultPaginationRequest)}
                        />
                        <Col md={12} className="d-flex justify-content-end">
                            <div>
                                <AddButton isRender={true} onClick={onAdd} />
                            </div>
                        </Col>
                    </div>
                </Card.Body>
            </Card>
            <PaginationTable
                onSearch={handleFetchData}
                onPaginate={handleFetchData}
                onRowClick={(item) => {}}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
            {Prompt}
        </Fragment>
    );
};

export default IIRCategoryBasePage;
