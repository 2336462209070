import { SIReportDetailResponse } from 'api/v2/safe-inspection/model/siReport';
import siReportApi from 'api/v2/safe-inspection/siReportApi';
import { DeleteButton, DownloadButton } from 'components/Button';
import { CloseButton } from 'components/Button/CloseButton';
import usePromptInputText from 'components/Modal/PromptInputText';
import ToastConfirm from 'components/Modal/ToastConfirm';
import DataListView from 'components/shared/DataListView';
import Color from 'enum/color';
import { SIReportStatus, SIReportType } from 'enum/safetyInspection';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { ModalDetailProps } from 'models/modalDetailProps';
import { useEffect, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SIGroupContent from './group.component';

const SIReportDetailPage = (props: ModalDetailProps) => {
    const { t } = useTranslation();
    const [report, setReport] = useState<SIReportDetailResponse | null>(null);
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    useEffect(() => {
        loadReport();
    }, []);
    const loadReport = () => {
        if (props.id)
            siReportApi
                .getById(props.id)
                .then((res) => {
                    setReport(res);
                })
                .catch((n) => {});
    };
    const { control } = useForm();

    const onDelete = () => {
        if (report)
            siReportApi
                .delete(report.id)
                .then((n) => {
                    props.onDeleted && props.onDeleted();
                })
                .catch((n) => {});
    };

    const onCloseReportScheduled = async (groupId: string) => {
        if (!report) return;
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            description: '',
            defaultValue: '',
        });
        siReportApi
            .closeScheduled(report.id, groupId, text)
            .then((n) => {
                loadReport();
            })
            .catch((n) => {});
    };
    const onCloseReportDaily = async () => {
        if (!report) return;
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            description: '',
            defaultValue: '',
        });
        siReportApi
            .closeDaily(report.id, text)
            .then((n) => {
                loadReport();
            })
            .catch((n) => {});
    };
    const onDownload = () => {
        if (report) {
            siReportApi
                .downloadReport(report.id, report.reportType)
                .then((n) => {})
                .catch((n) => {});
        }
    };
    const generageName = (groupTemplateId: string, itemTemplateId: string, name: string) => {
        return `gr-${groupTemplateId}-item-${itemTemplateId}-${name}`;
    };

    return (
        report && (
            <div>
                <Card>
                    <div className="row">
                        <div className="d-flex justify-content-end ">
                            <DownloadButton onClick={onDownload} />
                            <CloseButton onClick={onCloseReportDaily} isRender={report?.canCloseDaily === true} />
                            <ToastConfirm message={t(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                <DeleteButton isRender={report?.canDelete === true} />
                            </ToastConfirm>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <DataListView
                                datas={[
                                    {
                                        label: lang.text(Translations.form.site.name),
                                        value: report.companyName,
                                    },
                                    {
                                        label: lang.text(Translations.form.area.name),
                                        value: report.areaName,
                                    },
                                    {
                                        label: lang.text(Translations.common.reportType),
                                        value: report.reportType,
                                    },

                                    {
                                        label: lang.text(Translations.common.status),
                                        value: report.status,
                                    },

                                    {
                                        label: lang.text(Translations.common.createdBy),
                                        value: report.createdUserName,
                                    },
                                    {
                                        label: lang.text(Translations.common.createdTime),
                                        value: report.createdTime && formatDateTime(report.createdTime),
                                    },
                                    {
                                        label: lang.text(Translations.form.safetyInspections.ok),
                                        value: (
                                            <div className="p-0">
                                                <Badge bg={Color.success}>{report.summary?.percentItemOk} %</Badge>
                                            </div>
                                        ),
                                    },
                                    {
                                        label: lang.text(Translations.form.safetyInspections.not),
                                        value: (
                                            <div className="p-0">
                                                <Badge bg={Color.danger}>{report.summary?.percentItemFailed} %</Badge>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-6">
                            {!report.isSafe && report.status == SIReportStatus.Closed && (
                                <DataListView
                                    datas={[
                                        {
                                            label: lang.text(Translations.common.closedBy),
                                            value: report.closedUserFullName,
                                        },
                                        {
                                            label: lang.text(Translations.common.closedDate),
                                            value: report.closedDate && formatDateTime(report.closedDate),
                                        },
                                        {
                                            label: lang.text(Translations.common.description),
                                            value: report.closedDescription,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                </Card>
                <Card>
                    {report.groups?.map((group) => (
                        <div className="row">
                            <div className="col-md-12">
                                <SIGroupContent
                                    closeGroupScheduled={onCloseReportScheduled}
                                    generageName={generageName}
                                    control={control}
                                    readonly={true}
                                    key={group.groupTemplateId}
                                    group={group}
                                    showPIC={report.reportType == SIReportType.Scheduled}
                                />
                            </div>
                        </div>
                    ))}
                </Card>
                {PromptInputText}
            </div>
        )
    );
};

export default SIReportDetailPage;
