import { MODAL_BOX_CLASSNAME } from 'constants/common';
import { getCurrentLayout, LAYOUT_TYPE } from 'hooks/useLayout';
import { ReactNode, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ModalFullScreenProps {
    title?: string;
    showModal: boolean;
    handleCloseModal: () => void;
    bodyModal: ReactNode;
    footerModal?: ReactNode;
    scrollable?: boolean;
}
export function ModalFullScreen({ showModal, handleCloseModal, bodyModal, footerModal, title }: ModalFullScreenProps) {
    const layout = getCurrentLayout();
    console.log(layout);
    return (
        <Modal className={layout === LAYOUT_TYPE.BOX ? MODAL_BOX_CLASSNAME : ''} fullscreen={true} scrollable={true} size="lg" backdrop="static" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyModal}</Modal.Body>
            <Modal.Footer>{footerModal}</Modal.Footer>
        </Modal>
    );
}
