import Card from 'components/shared/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AddButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import useModalHook from 'components/ModalHook';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import Routers from 'router/const/routers';
import tripApi from 'api/v2/trip/tripApi';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { useQuery } from 'hooks/useQuery';
import { CompanySelect } from 'components/Common';
import EquipmentSelectField from 'components/Common/EquipmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { SelectField } from 'components/FormControl';
import { getTripStatus } from 'constants/trip';
import moment from 'moment';
import Select from 'components/FormControl/Select';
import { TripFilter } from 'api/v2/trip/model/trip.model';

// eslint-disable-next-line react-hooks/exhaustive-deps
const TripPage = () => {
    const { query, setQuery } = useQuery();
    const cols = useMemo(
        () => [
            {
                field: 'fromPlaceName',
                title: lang.text(Translations.trip.from),
            },
            {
                field: 'toPlaceName',
                title: lang.text(Translations.trip.to),
            },
            {
                field: 'distance',
                title: lang.text(Translations.trip.distance),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'reason',
                title: 'Lý do',
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const statuses = useMemo(getTripStatus, []);
    const { Prompt } = useModalHook();
    var navigate = useNavigate();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { control, handleSubmit, getValues, reset, formState, setValue } = useForm();

    useEffect(() => {
        handleFetchData(query as PaginationBaseRequest);
    }, [query]);

    const handleFetchData = async (paginate: PaginationBaseRequest) => {
        var filter: TripFilter = {
            ...paginate,
            ...query,
        };
        tripApi
            //@ts-ignore
            .getByIndex(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((data) => {
                            return {
                                ...data,
                                fromPlaceName: data.fromPlace.displayName,
                                toPlaceName: data.toPlace.displayName,
                                createdTime: moment(data?.createdTime).format('DD/MM/yyyy HH:mm'),
                                action: (
                                    <ActionButtons2
                                        id={data?.id!}
                                        editAction={() => window.open(`${Routers.trip.trip}/${data?.id}`, '_blank')}
                                        viewPms={true}
                                        onViewDetail={() => window.open(`${Routers.trip.trip}/${data?.id}`, '_blank')}
                                    />
                                ),
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <form
                        className="w-100"
                        onSubmit={handleSubmit((data) => {
                            setQuery({
                                ...data,
                                currentPage: 1,
                            });
                        })}
                    >
                        <div className="row">
                            <CompanySelect defaultValue={query?.companyId} required={false} name="companyId" control={control} disable={false} readOnly={false} />
                            <EquipmentSelectField md={4} xs={12} defaultValue={query?.equipmentId} isMutiple={false} name="equipmentId" control={control} disable={false} readOnly={false} />
                            <Select md={4} xs={12} label={lang.text(Translations.common.status)} options={statuses} name={'status'} control={control} />

                            <UserSelectField
                                defaultValue={query?.driverId}
                                readOnly={false}
                                label={lang.text(Translations.equipment.operator)}
                                name="driverId"
                                control={control}
                                isMutiple={false}
                                md={4}
                                xs={12}
                            />
                            <UserSelectField
                                defaultValue={query?.passengerId}
                                readOnly={false}
                                label={lang.text(Translations.trip.passengers)}
                                name="passengerId"
                                control={control}
                                isMutiple={false}
                                md={4}
                                xs={12}
                            />
                            <div className="col-md-6 col-xs-12 d-flex justify-content-end align-items-end">
                                <AddButton isRender={true} onClick={() => navigate(`${Routers.trip.trip}/create`)} />
                                <FilterButton type="submit" />
                            </div>
                        </div>
                    </form>
                </Card.Body>
            </Card>

            <PaginationTable
                onSearch={(pagi) => {
                    defaultPaginationRequest.keySearch = pagi.keySearch;
                }}
                onPaginate={(p) => handleFetchData(p)}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={false}
                wrapperClass="mt-5"
            />
            {Prompt}
        </Fragment>
    );
};

export default TripPage;
