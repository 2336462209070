import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { RiskLevelResponse } from 'api/models';

export interface RiskLevelSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function RiskLevelSelect({ name = 'riskLevelId', control, required, md = 4, xs = 12, onValueChange, isAddAll, readOnly }: RiskLevelSelectProps) {
    const { currentCustomer } = useCustomer();
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [currentCustomer]);
    useEffect(() => {
        loadDatas();
    }, []);
    const loadDatas = () => {
        riskLevelApi
            .getByCurrentCustomer()
            .then((res) => {
                setOptions(res.datas?.map((item: RiskLevelResponse) => ({ label: item.fieldNameVn, value: item.id })) ?? []);
            })
            .catch(() => {});
    };

    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.common.riskLevel)}
            options={options}
            name={name}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            xs={xs}
            isAddAll={isAddAll}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default RiskLevelSelect;
