import { MODAL_BOX_CLASSNAME } from 'constants/common';
import { getCurrentLayout, LAYOUT_TYPE } from 'hooks/useLayout';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface ModalHookProps {
    show: boolean;
    title: string;
    body: React.ReactNode;
    modalSize: 'sm' | 'lg' | 'xl';
    isFullScreen: true | string;
    onClose: () => void;
    onConfirm: () => void;
}
export interface ModalHookComponentProps {
    onClose: () => void;
    onCompleted: (value: any) => void;
}
const ModalHook: React.FC<ModalHookProps> = ({ show, onClose, onConfirm, modalSize = 'lg', title, body, isFullScreen }) => {
    const layout = getCurrentLayout();
    return (
        <Modal className={layout === LAYOUT_TYPE.BOX ? MODAL_BOX_CLASSNAME : ''} show={show} onHide={onClose} size={modalSize} backdrop={'static'} fullscreen={isFullScreen}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t(Translations.modal.confirm.cancel)}
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => () => {
                        onConfirm();
                        onClose();
                    }}
                >
                    {t(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

interface OpenModalHookProps {
    title: string;
    body: React.ReactNode;
    modalSize?: 'sm' | 'lg' | 'xl';
    isFullScreen?: true | string;
    /**Reset route affter when close modal */
    isResetRoute?: boolean;
}
const useModalHook = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    // const [resolve, setResolve] = useState<(value?: any) => void>(() => {
    //     console.log('vao run resolve default');
    // });
    var resolve: any = undefined;
    const [modalSize, setModalSize] = useState<'sm' | 'lg' | 'xl'>('lg');
    const [title, setTitle] = useState<string>('');
    const [fullScreen, setfullScreen] = useState<true | string>('');
    const [body, setBody] = useState<React.ReactNode>();
    const [isResetRoute, setIsResetRoute] = useState<boolean>(false);
    const openModal = ({ title, body, modalSize, isFullScreen, isResetRoute }: OpenModalHookProps) => {
        setShow(true);
        setModalSize(modalSize ?? 'xl');
        setfullScreen(isFullScreen ?? '');
        setTitle(title);
        setBody(body);
        setIsResetRoute(isResetRoute ?? false);
        return new Promise<any>((resol, reject) => {
            resolve = resol;
        });
    };
    /**
     * Export pass to Body Component,
     * Because button save and close manage on body
     * when close resolved result with null
     */
    const onClose = () => {
        handleClose();
    };

    /**
     * Export pass to Body Component,
     * Because button save and close manage on body
     * when completed resolved result with value cusstomr,
     * Outsite component check that value, if is null, it is close envet, if othe it is success confirm event
     * base on that result action handle feeth data
     */
    const onCompleted = (val: any) => {
        setShow(false);
        resolve && resolve(val);
    };
    // const onSubmit = () => setShow(false);
    const handleClose = () => {
        setShow(false);
        if (isResetRoute) {
            navigate('', { replace: true });
        }
        resolve && resolve(false);
    };

    /**Do not use because button close and confirm manage on body component */
    const handleConfirm = () => {
        console.log('handleConfirm');
        resolve && resolve(false);
    };

    return {
        onCompleted,
        onClose,
        openModal,
        Prompt: <ModalHook modalSize={modalSize} body={body} title={title} show={show} onClose={handleClose} onConfirm={handleConfirm} isFullScreen={fullScreen} />,
    };
};

export default useModalHook;
