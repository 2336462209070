import { yupResolver } from '@hookform/resolvers/yup';

import { SITemplateGroupModel, SITemplateItemModel, SITemplateModel } from 'api/v2/safe-inspection/model/siTemplate';
import { AddButton, DeleteButton, UpdateButton } from 'components/Button';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import { InputField } from 'components/FormControl';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { ModalHookComponentProps } from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { EquipmentReportType } from 'enum';
import { SIReportType } from 'enum/safetyInspection';
import { generateGUID, generateRandomId } from 'helper';
import { EnumHelper } from 'helper/enum.helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Card, Col, Form, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import SIReportTypeSelect from '../component/siReportTypeSelect';
import siTemplateApi from 'api/v2/safe-inspection/siTemplateApi';
import SiGroupTemplateComponent from './group.component';

/// if template defined, it is udpate flow
interface SITemplateAction extends ModalHookComponentProps {
    templateId?: string | null;
}
const SIAddOrUpdateComponent: React.FC<SITemplateAction> = ({ onClose, onCompleted, templateId }) => {
    const [groups, setGroups] = useState<SITemplateGroupModel[]>([]);
    const [templateDetail, setTemplateDetail] = useState<SITemplateModel>();
    const schema = yup.object().shape({
        companyIds: yup.array().required(),
        name: yup.string().required().min(1).max(200),
        reportType: yup.string().required(),
    });
    useEffect(() => {
        if (templateId)
            siTemplateApi
                .getTemplateById(templateId)
                .then((n) => {
                    setTemplateDetail(n);
                    setGroups(n.groups);

                    reset({
                        companyIds: n.companyIds,
                        name: n.templateName,
                        reportType: n.reportType,
                    });
                })
                .catch((n) => {});
    }, [templateId]);

    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {
            companyIds: templateDetail?.companyIds ?? [],
            reportType: templateDetail?.reportType ?? EquipmentReportType.Daily,
            name: templateDetail?.templateName,
        },
        resolver: yupResolver(schema),
    });

    const onAddOrUpdateAsync = async () => {
        let form = getValues() as any;
        let newGroup = [...groups];
        for (let i = 0; i < newGroup.length; i++) {
            const group = newGroup[i];
            group.groupName = form[`group-${group.id}`];
            if (!group.groupName?.hasValue()) {
                toast.error('Vui lòng nhập đầy đủ thông tin' + ': index - ' + i);
                return;
            }
            for (let j = 0; j < group.items.length; j++) {
                const item = group.items[j];
                item.itemName = form[`group-${group.id}-item-${item.id}`];
                item.id = null;
                if (!item.itemName?.hasValue()) {
                    toast.error('Vui lòng nhập đầy đủ thông tin');
                    return;
                }
            }
            group.id = null;
        }

        let reportType = SIReportType[form.reportType as keyof typeof SIReportType];
        if (!templateId) {
            // is case add
            var payload: SITemplateModel = {
                companyIds: form.companyIds,
                templateName: form.name,
                reportType: reportType,
                groups: groups,
                id: null,
            };

            siTemplateApi
                .createTemplate(payload)
                .then((n) => {
                    onCompleted(true);
                })
                .catch((n) => {});
        } else {
            // case udpate
            var payloadUpdate: SITemplateModel = {
                companyIds: form.companyIds,
                templateName: form.name,
                reportType: form.reportType,
                id: templateId,
                groups: groups,
            };
            // case update
            siTemplateApi
                .updateTemplate(payloadUpdate)
                .then((n) => {
                    onCompleted(true);
                })
                .catch((n) => {});
        }
    };

    const onAddGroup = () => {
        let newGroups = [...groups];
        newGroups.unshift({
            id: generateRandomId(),
            groupName: '',
            items: [],
            // templateId: '',
        });
        setGroups(newGroups);
    };
    const onAddItem = (group: SITemplateGroupModel) => {
        group.items.unshift({
            // groupId: group.id ?? '',
            itemName: '',
            id: generateGUID(),
        });
        let newGroups = [...groups];
        setGroups(newGroups);
    };
    const onDeleteGroup = (group: SITemplateGroupModel) => {
        var newGroups = groups.filter((n) => n.id != group.id);
        setGroups(newGroups);
    };
    const onDeleteItem = (group: SITemplateGroupModel, item: SITemplateItemModel) => {
        group.items = group.items.filter((n) => n.id != item.id);
        setGroups([...groups]);
    };
    return (
        <form onSubmit={handleSubmit(onAddOrUpdateAsync)}>
            <div className="row">
                <InputField md={4} xs={12} name="name" control={control} label={t(Translations.common.name)} />

                <OrganizationUnitMultiSelect md={4} xs={12} name="companyIds" control={control} isSingleCompany={false} isSingleArea={false} isShowAreaSelect={false} requiredCompany={true} />
                <SIReportTypeSelect md={4} xs={12} name="reportType" control={control} />
            </div>
            <Row>
                <div className="d-flex justify-content-end gap-3">
                    <AddButton isRender={true} onClick={onAddGroup} />
                </div>
            </Row>
            <div className="row">
                <Card>
                    <Card.Body>
                        {groups && (
                            <Accordion>
                                {groups.map((group, index) => (
                                    <SiGroupTemplateComponent index={index} group={group} onDeleteGroup={onDeleteGroup} onDeleteItem={onDeleteItem} onAddItem={onAddItem} control={control} />
                                ))}
                            </Accordion>
                        )}
                    </Card.Body>
                </Card>
            </div>

            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {t(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {t(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default SIAddOrUpdateComponent;
