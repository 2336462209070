import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'components/shared/Card';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { AreaSelect, CompanySelect } from 'components/Common';
import usePromptInputText from 'components/Modal/PromptInputText';
import { FilterButton } from 'components/Button/FilterButton';
import { formatDateTime } from 'helper';
import secGateApi from 'api/v2/sec/secGateApi';
import { SECGateResponse } from 'api/v2/sec/model/gate.model';
import { toast } from 'react-toastify';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const SECGatePage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    // const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'organiationUnitName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const { control } = useForm();
    const [companyId, setCompanyId] = useState<string>('');
    const [areaId, setAreaId] = useState<string>('');
    // useEffect(() => {
    //     console.log('Please select company', companyId);
    //     handleFetchData(defaultPaginationRequest);
    // }, [companyId]);
    const getOrganizationId = () => (areaId.hasValue() ? areaId : companyId);
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        console.log(areaId, companyId, 'getOrganizationId');
        secGateApi
            .getPagination({
                ...pagination,
                organiationUnitId: getOrganizationId(),
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        if (!companyId?.hasValue()) {
            toast.error(lang.text(Translations.error.secRequiredOrg));
            return;
        }
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        text &&
            secGateApi
                .create({
                    name: text,
                    organizationUnitId: getOrganizationId(),
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: SECGateResponse) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: data.name,
        });
        text &&
            secGateApi
                .update({
                    name: text,
                    id: data.id,
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        secGateApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="row w-100">
                        <CompanySelect name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                        <AreaSelect
                            name="areaId"
                            control={control}
                            required={true}
                            companyId={companyId}
                            readOnly={false}
                            onAreaChange={(a) => {
                                setAreaId(a ?? '');
                            }}
                        />

                        <div className="col-md-12">
                            <div className=" d-flex justify-content-end">
                                <AddButton isRender={true} onClick={onAdd} />
                                <FilterButton onClick={() => handleFetchData(defaultPaginationRequest)} className="mx-2" />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />
            {PromptInputText}
        </Fragment>
    );
};
export default SECGatePage;
