import { yupResolver } from '@hookform/resolvers/yup';
import { PPRReportTaskRequest } from 'api/v2/ppr/model/ppr.model';
import { AddButton } from 'components/Button';
import { AreaSelect } from 'components/Common';
import { DepartmentSelect } from 'components/Common/DepartmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { SelectField, SelectOption, TextAreaField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { PPRTaskType } from 'enum/ppr';
import { generateGUID } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

const PPRAddTaskComponent = ({ companyId, onSubmit, currentTaskUpdate }: { companyId: string; currentTaskUpdate?: PPRReportTaskRequest; onSubmit: (payload: PPRReportTaskRequest) => void }) => {
    const [pprTaskType, setPprTaskType] = useState<PPRTaskType>(PPRTaskType.AREA);
    const [targetObjectName, setTargetObjectName] = useState<string>(''); // [1
    const [taskTypeOptions, setTaskTypeOptions] = useState<SelectOption[]>([
        { label: lang.text(Translations.form.area.name), value: PPRTaskType.AREA },
        { label: lang.text(Translations.common.employee), value: PPRTaskType.USER },
        { label: lang.text(Translations.form.department.name), value: PPRTaskType.DEPARTMENT },
    ]);
    useEffect(() => {
        resetStep({ ...currentTaskUpdate });
        setTargetObjectName(currentTaskUpdate?.targetObjectName ?? '');
    }, [currentTaskUpdate]);

    const schemaTask = yup.object().shape({
        fromDate: yup.date().required(),
        toDate: yup.date().required(),
        userId: yup.string().nullable().notRequired(),
        taskType: yup.string().required(),
        areaId: yup.string().nullable().notRequired(),
        departmentId: yup.string().nullable().notRequired(),
        descriptionTask: yup.string().required(),
        statusTask: yup.string().required(),
    });

    const {
        reset: resetStep,
        control: control,
        getValues: getValuesStep,
        handleSubmit: handleSubmitStep,
        formState: { errors: errorsStep },
    } = useForm({ resolver: yupResolver(schemaTask) });
    const submit = () => {
        var form = getValuesStep();
        var payload: PPRReportTaskRequest = {
            ...form,
            taskType: pprTaskType,
            id: currentTaskUpdate?.id ? currentTaskUpdate.id : generateGUID(),
            targetObjectName: targetObjectName,
        };
        onSubmit(payload);
        resetStep({
            userId: null,
            areaId: null,
            departmentId: null,
            descriptionTask: '',
            statusTask: '',
        });
    };
    return (
        <form onSubmit={handleSubmitStep(submit)}>
            <div className="row">
                <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={12} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={12} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                <SelectField
                    label={lang.text(Translations.wpr.ppr.type)}
                    control={control}
                    name="taskType"
                    options={taskTypeOptions}
                    md={4}
                    xs={12}
                    onValueChange={(value) => {
                        setPprTaskType(value as PPRTaskType);
                    }}
                />

                {pprTaskType === PPRTaskType.USER && (
                    <UserSelectField
                        onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                            setTargetObjectName(value?.label ?? '');
                        }}
                        readOnly={false}
                        label={lang.text(Translations.common.employee)}
                        name="pICId"
                        control={control}
                        required={true}
                        isMutiple={false}
                        md={4}
                        xs={12}
                    />
                )}
                {pprTaskType === PPRTaskType.AREA && (
                    <AreaSelect
                        onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                            setTargetObjectName(value?.label ?? '');
                        }}
                        md={4}
                        xs={12}
                        name="areaId"
                        control={control}
                        required={true}
                        companyId={companyId}
                        readOnly={false}
                    />
                )}

                {pprTaskType === PPRTaskType.DEPARTMENT && (
                    <DepartmentSelect
                        onSelectedOptionChange={(value?: SelectOption | undefined | null) => {
                            setTargetObjectName(value?.label ?? '');
                        }}
                        md={4}
                        xs={12}
                        name="departmentId"
                        control={control}
                        required={true}
                        companyId={companyId}
                        readOnly={false}
                    />
                )}

                <TextAreaField md={12} xs={12} label={lang.text(Translations.wpr.ppr.descriptionTask)} name="descriptionTask" control={control} required />
                <TextAreaField md={12} xs={12} label={lang.text(Translations.wpr.ppr.statusTask)} name="statusTask" control={control} required />
                <div className="d-flex justify-content-end">
                    <AddButton isRender={true} type="submit" text={lang.text(Translations.common.addNew)} />
                </div>
            </div>
        </form>
    );
};
export default PPRAddTaskComponent;
