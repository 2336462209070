import equipmentApi from 'api/v2/equipmentApi';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, SelectField, SelectOption, TextAreaField, UploadImageField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EquipmentCreateRequest, EquipmentDetailResponse } from 'api/models';
import { AreaSelect, ContractorSelect } from 'components/Common';
import { CompanySelect } from 'components/Common/CompanySelect';
import * as yup from 'yup';
import { Card, Col, Row } from 'react-bootstrap';
import { EquipmentReportType } from 'enum';
import { SaveButton } from 'components/Button';
import UserSelectField from 'components/Common/UserSelect';
import DateTimeField from 'components/FormControl/DateTimeField';

interface EquipmenmtModifyFormProps {
    onSaveChange?: (data: EquipmentCreateRequest) => void;
    equipment?: EquipmentDetailResponse;
    readOnly?: boolean;
}
const EquipmenmtModifyForm = ({ readOnly, equipment, onSaveChange }: EquipmenmtModifyFormProps) => {
    const { t } = useTranslation();
    const [types, setTypes] = useState<SelectOption[]>([]);
    const [templatesDaily, setTemplatesDaily] = useState<SelectOption[]>([]);
    const [templatesScheduled, setTemplatesScheduled] = useState<SelectOption[]>([]);
    const [companyId, setCompanyId] = useState<string | null>();
    useEffect(() => {
        reset({ ...equipment });
    }, [equipment]);

    const onCompanyChange = (companyId: string | null) => {
        setCompanyId(companyId);
        setTemplatesDaily([]);
        setTemplatesScheduled([]);
        if (companyId == null) {
            setTypes([]);
        } else {
            equipmentApi
                .getTypeByCompanies([companyId])
                .then((types) => {
                    var newTypes = types.toSelectOptions('typeGroupName', 'id');
                    setTypes(newTypes);
                })
                .catch((e) => {});
        }
    };

    const onTypeGroupChange = (typeId: string | null) => {
        if (typeId == null) {
            setTemplatesDaily([]);
            setTemplatesScheduled([]);
        } else {
            equipmentApi
                .getTemplateDropdown(null, typeId)
                .then((templates) => {
                    setTemplatesDaily(templates.filter((n) => n.reportType == EquipmentReportType.Daily).toSelectOptions('templateName', 'id') ?? []);
                    setTemplatesScheduled(templates.filter((n) => n.reportType == EquipmentReportType.Scheduled).toSelectOptions('templateName', 'id') ?? []);
                })
                .catch((e) => {});
        }
    };

    const schema = yup.object().shape({
        companyId: yup.string(),
        brand: yup.string().required(),
        equipmentCode: yup.string().required(),
        equipmentName: yup.string().required(),
        yearOfManuf: yup.number().positive().integer(),
    });

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<EquipmentCreateRequest>({
        resolver: yupResolver(schema),
    });

    const saveEquipment = async () => {
        let modelRequest: EquipmentCreateRequest = getValues();
        onSaveChange && onSaveChange(modelRequest);
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(saveEquipment)}>
                <Row>
                    <Col xl="3" lg="4" className="">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12} sm={6}>
                                        {readOnly && <div style={{ maxWidth: '266px' }} className="border mb-2" dangerouslySetInnerHTML={{ __html: equipment?.qRCode ?? '' }} />}
                                    </Col>
                                    {(!readOnly || equipment?.imageOne) && (
                                        <Col md={12} sm={6}>
                                            <UploadImageField control={control} src={equipment?.imageOne} btnText={t(Translations.equipment.frontView)} name="imageOne" readOnly={readOnly} />
                                        </Col>
                                    )}
                                    {(!readOnly || equipment?.imageTwo) && (
                                        <Col md={12} sm={6}>
                                            <UploadImageField control={control} src={equipment?.imageTwo} btnText={t(Translations.equipment.sideView)} name="imageTwo" readOnly={readOnly} />
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="9" lg="8">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <CompanySelect
                                        name="companyId"
                                        control={control}
                                        required={true}
                                        disable={false}
                                        readOnly={readOnly}
                                        onCompanyChange={(companyId: string | null) => {
                                            onCompanyChange(companyId);
                                        }}
                                    />
                                    <AreaSelect name="areaId" control={control} required={true} companyId={companyId} readOnly={readOnly} />

                                    <ContractorSelect name="contractorId" control={control} md={4} xs={12} readOnly={readOnly} />

                                    <SelectField
                                        label={t(Translations.equipment.type)}
                                        options={types}
                                        name="typeGroupId"
                                        control={control}
                                        required={true}
                                        isMutiple={false}
                                        md={4}
                                        xs={12}
                                        onValueChange={onTypeGroupChange}
                                        readOnly={readOnly}
                                    />
                                    <SelectField
                                        readOnly={readOnly}
                                        label={t(Translations.equipment.dailyReportTemplate)}
                                        options={templatesDaily}
                                        name="templateDailyId"
                                        control={control}
                                        required={false}
                                        isMutiple={false}
                                        md={4}
                                        xs={12}
                                    />
                                    <SelectField
                                        readOnly={readOnly}
                                        label={t(Translations.equipment.scheduledReportTemplate)}
                                        options={templatesScheduled}
                                        name="templateScheduledId"
                                        control={control}
                                        required={false}
                                        isMutiple={false}
                                        md={4}
                                        xs={12}
                                    />
                                    <UserSelectField
                                        readOnly={readOnly}
                                        label={t(Translations.equipment.operator)}
                                        name="operatorId"
                                        control={control}
                                        required={true}
                                        isMutiple={false}
                                        md={4}
                                        xs={12}
                                    />

                                    <UserSelectField
                                        readOnly={readOnly}
                                        label={t(Translations.equipment.supervisor)}
                                        name="supervisorId"
                                        control={control}
                                        required={false}
                                        isMutiple={false}
                                        md={4}
                                        xs={12}
                                    />
                                </Row>
                                <Row>
                                    <InputField required={true} control={control} name="equipmentCode" label={t(Translations.equipment.code)} md={4} xs={12} readOnly={readOnly} />
                                    <InputField required={true} control={control} name="equipmentName" label={t(Translations.equipment.name)} md={4} xs={12} readOnly={readOnly} />
                                    <InputField type="number" control={control} name="yearOfManuf" md={4} xs={12} label={t(Translations.equipment.manufacturingYear)} readOnly={readOnly} />
                                    <InputField required={false} control={control} name="serialNo" label={t(Translations.equipment.serialNo)} md={4} xs={12} readOnly={readOnly} />
                                    <InputField required={true} control={control} name="brand" label={t(Translations.equipment.brand)} md={4} xs={12} readOnly={readOnly} />
                                    <DateTimeField
                                        label={t(Translations.equipment.inspectedDate)}
                                        md={4}
                                        xs={12}
                                        isShowTimePicker={false}
                                        control={control}
                                        name="inspectedDate"
                                        readOnly={readOnly}
                                        defaultDate={equipment?.inspectedDate}
                                    />
                                    <DateTimeField
                                        label={t(Translations.equipment.expiredDate)}
                                        md={4}
                                        xs={12}
                                        isShowTimePicker={false}
                                        control={control}
                                        name="inspectionExpriredDate"
                                        readOnly={readOnly}
                                        defaultDate={equipment?.inspectionExpriredDate}
                                    />
                                    <InputField name="inspector" control={control} label={t(Translations.equipment.inspector)} md={4} xs={12} readOnly={readOnly} />
                                    <TextAreaField name="specifications" control={control} label={t(Translations.equipment.technicalSpecifications)} md={12} readOnly={readOnly} />
                                    {readOnly === false && (
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <SaveButton type="submit" />
                                        </div>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </Fragment>
    );
};

export default EquipmenmtModifyForm;
