import { yupResolver } from '@hookform/resolvers/yup';
import { locationValidation } from 'api/models/common/locationModel';
import { TripReqChangeRoute, TripReqChangeRouteStatus } from 'api/v2/trip/model/trip.model';
import tripApi from 'api/v2/trip/tripApi';
import { InputField, TextAreaField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { LocationField } from 'components/FormControl/LocationField';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect } from 'react';
import { Badge, Card, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import RestPlacesComponent from './components/restPlaces.component';

//70eec63e-b0de-447b-90b7-ce60a60c7cae
interface TripRequestChangeRouteProps extends ModalHookComponentProps {
    detail?: TripReqChangeRoute | null;
    isReadOnly?: boolean;
}
export default function TripRequestChangeRoute({ detail, onCompleted, isReadOnly = false }: TripRequestChangeRouteProps) {
    const { id } = useParams();
    const { userPms } = useAuth();

    const schema = yup.object().shape({
        reason: yup.string().required(),
        restPlaces: yup.array().of(locationValidation.required()).optional(),
        fromPlace: locationValidation.required(),
        toPlace: locationValidation.required(),
        planCompletedTime: yup.date().required(),
        planStartTime: yup.date().required(),
        distance: yup.number().required(),
        tripId: yup.string().required(),
    });

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        getValues,
        trigger,
        formState: { errors },
    } = useForm<TripReqChangeRoute>({ resolver: yupResolver(schema) });

    useEffect(() => {
        detail &&
            reset({
                fromPlace: detail.fromPlace,
                toPlace: detail.toPlace,
                distance: detail.distance,
                planStartTime: detail.planStartTime,
                planCompletedTime: detail.planCompletedTime,
                reason: detail.reason,
                restPlaces: detail.restPlaces,
                tripId: detail.id,
            });
    }, []);
    const parseStatusString = (status: TripReqChangeRouteStatus) => {
        if (status === TripReqChangeRouteStatus.CREATED) return <Badge bg="warning">{lang.text(Translations.common.statusDisplay.pending)}</Badge>;
        if (status === TripReqChangeRouteStatus.APPROVED) return <Badge bg="success">{lang.text(Translations.common.statusDisplay.approved)}</Badge>;
        if (status === TripReqChangeRouteStatus.REJECT) return <Badge bg="danger">{lang.text(Translations.common.statusDisplay.refused)}</Badge>;
    };
    const onSubmit = (data: TripReqChangeRoute) => {
        const values: TripReqChangeRoute = {
            ...data,
            tripId: id!,
        };
        tripApi
            .requestChangeRoute(values)
            .then((res) => {
                onCompleted(true);
            })
            .catch(() => {});
    };

    return (
        <Fragment>
            <div>
                {/* @ts-ignore */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <Row>
                            <div>{detail?.status && parseStatusString(detail.status)}</div>
                        </Row>
                        <Row>
                            <LocationField md={4} xs={12} name="fromPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.from)} control={control} required={true} />
                            <LocationField md={4} xs={12} name="toPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.to)} control={control} required={true} />
                            <InputField required={true} md={4} xs={12} control={control} name="distance" label={lang.text(Translations.trip.estimatedDistance)} readOnly={isReadOnly} />
                            <DateTimeField
                                isShowTimePicker={true}
                                md={4}
                                xs={12}
                                name={'planStartTime'}
                                label={lang.text(Translations.trip.estimatedTimeFrom)}
                                control={control}
                                readOnly={isReadOnly}
                            />
                            <DateTimeField
                                isShowTimePicker={true}
                                md={4}
                                xs={12}
                                name={'planCompletedTime'}
                                label={lang.text(Translations.trip.estimatedTimeTo)}
                                control={control}
                                readOnly={isReadOnly}
                            />
                            <TextAreaField md={12} name="reason" control={control} defaultValue={''} label={'Lý do'} readOnly={isReadOnly}></TextAreaField>
                        </Row>
                    </Card>
                    <RestPlacesComponent trigger={trigger} setValue={setValue} canAdd={!isReadOnly} isReadOnly={isReadOnly} name="restPlaces" required={false} control={control} />
                </form>
            </div>
        </Fragment>
    );
}
