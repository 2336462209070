import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { PPRReportFilterRequest, PPRReportResponse } from 'api/v2/ppr/model/ppr.model';
import pprReportApi from 'api/v2/ppr/pprReportApi';
import { FilterButton } from 'components/Button/FilterButton';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import { SelectField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import { PaginationTable } from 'components/shared/PaginationTable';
import { formatDateTime } from 'helper';
import { EnumHelper } from 'helper/enum.helper';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PPRDetailPage from './pprDetailPage';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const PPRListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'contractorName',
                title: lang.text(Translations.contractor),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const reportStatus = useMemo(() => EnumHelper.generatePPRReportStatus(), []);
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<PPRReportResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { control, handleSubmit, getValues } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as PPRReportFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        pprReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                status: <Badge bg={EnumHelper.getPPRReportStatusColor(n.status)}>{n.status}</Badge>,
                                action: (
                                    <ActionButtons2
                                        id={n.id}
                                        onViewDetail={() => {
                                            setCurrentDetailId(n.id);
                                        }}
                                    />
                                ),
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                        <div className="row">
                            <CompanySelect md={4} xs={12} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={4} xs={12} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                            <ContractorSelect md={4} xs={12} name="contractorId" control={control} required={false} readOnly={false} />
                            <SelectField label={t(Translations.common.status)} options={reportStatus} name="status" control={control} isMutiple={false} unSelectValue={''} md={4} xs={12} />
                            <UserSelectField md={4} xs={12} readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={false} isMutiple={false} />

                            <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={6} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                            <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={6} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                            <div className="col-md-12">
                                <div className="d-flex justify-content-end">
                                    <FilterButton type="submit" className="mx-2 mt-4" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Card.Body>
            </Card>
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />
            <ModalFullScreen
                title={lang.text(Translations.common.reportDetail)}
                bodyModal={
                    <PPRDetailPage
                        onDeleted={() => {
                            handleFetchData(defaultPaginationRequest);
                            setCurrentDetailId(undefined);
                        }}
                        id={currentDetailId}
                    />
                }
                showModal={currentDetailId?.hasValue() ?? false}
                handleCloseModal={() => {
                    setCurrentDetailId(undefined);
                }}
            />
        </Fragment>
    );
};

export default PPRListPage;
