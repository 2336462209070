import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import kpiReportApi from 'api/v2/kpi/kpiReportApi';
import { KPIReportFilter, KpiReportResponse } from 'api/v2/kpi/model/kpiReport.model';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect } from 'components/Common';
import DateTimeField from 'components/FormControl/DateTimeField';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import { PaginationTable } from 'components/shared/PaginationTable';
import { formatDateTime } from 'helper';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { KPIDetailReportPage } from '..';
import KPITypeSelect from '../kpiType/kpiTypeSelect.component';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const KPIListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'typeName',
                title: lang.text(Translations.kpis.type),
            },
            {
                field: 'date',
                title: lang.text(Translations.form.safetyInspections.deadline),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    const [paginationData, setPaginationData] = useState<PaginationBase<KpiReportResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [typeId, setTypeId] = useState<string | null>(null);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as KPIReportFilter;

        kpiReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                action: <ActionButtons2 id={n.id} onViewDetail={() => setCurrentDetailId(n.id)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row">
                                <div className="row">
                                    <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={12} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                    <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={12} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                                    <CompanySelect md={4} xs={12} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                    <KPITypeSelect
                                        isRequiredCompany={true}
                                        control={control}
                                        companyId={companyId}
                                        name="typeId"
                                        md={4}
                                        xs={12}
                                        required={false}
                                        onValueChange={(id) => setTypeId(id)}
                                    />
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <FilterButton type="submit" className="mx-2 mt-3" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />
                        <ModalFullScreen
                            title={lang.text(Translations.common.reportDetail)}
                            bodyModal={
                                <KPIDetailReportPage
                                    onDeleted={() => {
                                        handleFetchData(defaultPaginationRequest);
                                        setCurrentDetailId(undefined);
                                    }}
                                    id={currentDetailId}
                                />
                            }
                            showModal={currentDetailId?.hasValue() ?? false}
                            handleCloseModal={() => {
                                setCurrentDetailId(undefined);
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default KPIListPage;
