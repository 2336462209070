import { Fragment, useState } from 'react';

import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { PPRReportCreateRequest, PPRReportTaskRequest } from 'api/v2/ppr/model/ppr.model';
import pprReportApi from 'api/v2/ppr/pprReportApi';
import { SaveButton } from 'components/Button';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { formatDate } from 'helper';
import { lang } from 'i18n/lang';
import { Accordion } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import PPRAddTaskComponent from './pprAddTaks.component';

const PPRReportCreatePage = () => {
    const { t } = useTranslation();

    const [companyId, setCompanyId] = useState<string>('');
    const [steps, setSteps] = useState<PPRReportTaskRequest[]>([]);
    const [updatingStep, setUpdatingStep] = useState<PPRReportTaskRequest>();

    const schema = yup.object().shape({
        areaId: yup.string().notRequired().nullable(),
        companyId: yup.string().required(),
        contractorId: yup.string().required(),
        pICId: yup.string().required(),
    });
    const {
        control,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const SaveChange = () => {
        if (!steps.length || steps.length === 0) {
            toast.error('Nhập ít nhất một công việc');
            return;
        }
        // if ( confirm('Bạn có chắc chắn muốn tạo báo cáo?') === false) return;
        var form = getValues();
        var payload: PPRReportCreateRequest = {
            ...form,
            tasks: steps,
            images: [],
            isSended: false,
            areaId: form.areaId ?? null,
        };
        pprReportApi
            .create(payload)
            .then((res) => {
                console.log(res);
            })
            .catch(() => {});
    };
    const removeStep = (obj: PPRReportTaskRequest) => {
        var newSteps = steps.filter((item) => item.id !== obj.id);
        setSteps(newSteps);
    };
    const submitAddTask = (request: PPRReportTaskRequest) => {
        let newSteps = [...steps, request];
        setSteps(newSteps);
    };
    return (
        <Fragment>
            <form onSubmit={handleSubmit(SaveChange)}>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <h4 className="card-title  ">
                            <span>Tạo báo cáo</span>
                        </h4>
                        <SaveButton />
                    </Card.Header>
                    <Card.Body>
                        <div className="row">
                            <CompanySelect md={4} xs={12} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={4} xs={12} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                            <ContractorSelect md={4} xs={12} name="contractorId" control={control} readOnly={false} />
                            <UserSelectField md={4} xs={12} readOnly={false} label={lang.text(Translations.common.pic)} name="pICId" control={control} required={true} isMutiple={false} />
                        </div>
                    </Card.Body>
                </Card>
            </form>

            <div className="row">
                <div className="col-md-6">
                    <Card className="border border-warning">
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">
                                {lang.text(Translations.common.addNew)} {lang.text(Translations.wpr.ppr.task).toLocaleLowerCase()}
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <PPRAddTaskComponent currentTaskUpdate={updatingStep} companyId={companyId} onSubmit={submitAddTask} />
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">{lang.text(Translations.wpr.ppr.taskList)}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <Accordion defaultActiveKey="0">
                                    {steps.map((item, index) => (
                                        <Accordion.Item eventKey={index.toString()} className="">
                                            <Accordion.Header className="d-flex justify-content-between">
                                                <div className="d-flex w-100 justify-content-between px-2">
                                                    <span># {index + 1}</span>
                                                    <ActionButtons2
                                                        id={item.id ?? ''}
                                                        onDelete={(id: string) => {
                                                            removeStep(item);
                                                            return Promise.resolve();
                                                        }}
                                                        editAction={() => {
                                                            setUpdatingStep({ ...item });
                                                            removeStep(item);
                                                        }}
                                                    />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {[
                                                    { label: lang.text(Translations.common.fromDate), value: formatDate(item.fromDate) },
                                                    { label: lang.text(Translations.common.toDate), value: formatDate(item.toDate) },
                                                    { label: lang.text(Translations.wpr.ppr.descriptionTask), value: item.descriptionTask },
                                                    { label: lang.text(Translations.wpr.ppr.type), value: item.taskType },
                                                    { label: lang.text(Translations.wpr.ppr.statusTask), value: item.statusTask },
                                                    { label: lang.text(Translations.wpr.ppr.object), value: item.targetObjectName },
                                                ].map((item, index) => (
                                                    <div key={index} className="d-flex">
                                                        <b style={{ minWidth: '25%' }}>{item.label} </b>
                                                        <span style={{ minWidth: '75%' }}>{item.value}</span>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default PPRReportCreatePage;
