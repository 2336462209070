import equipmentApi from 'api/v2/equipmentApi';
import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import Select from 'components/FormControl/Select';
import SelectSearch from 'components/FormControl/SelectSearch';
import SelectSearchField from 'components/FormControl/SelectSearchField';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useController } from 'react-hook-form';

export interface EquipmentSelectFieldProps extends SelectFieldProps {
    companyId?: string;
}

export const EquipmentSelectField: React.FC<EquipmentSelectFieldProps> = (props: EquipmentSelectFieldProps) => {
    const [equipments, setEquipments] = useState<SelectOption[]>([]);
    const loadEquipments = async () => {
        equipmentApi
            .getPagination({ rowsPerPage: 300, currentPage: 1, companyId: props?.companyId })
            .then((e: any) => {
                setEquipments(e?.datas?.map((d: any) => ({ value: d?.id, label: [d?.companyName || '', d?.equipmentCode || ''].join(' - ') })));
            })
            .catch((n) => {});
    };
    const {
        field,
        fieldState: { error, invalid },
    } = useController({
        name: props?.name,
        control: props.control,
    });

    useEffect(() => {
        loadEquipments();
    }, []);

    useEffect(() => {
        loadEquipments();
    }, [props.companyId]);

    return props?.isMutiple ? (
        <div className={`col-md-${props?.md} col-xs-${props?.xs}`}>
            <Form.Group className="">
                <Form.Label>{props?.label ?? lang.text(Translations.equipment.equipment)}</Form.Label>
                <Typeahead
                    selected={
                        Array.isArray(field.value)
                            ? field.value?.map((v: any) => ({
                                  value: v,
                                  label: equipments?.find((u) => u.value === v)?.label || '',
                              }))
                            : []
                    }
                    labelKey={(option: any) => {
                        return option.label;
                    }}
                    multiple
                    onChange={(e) => {
                        props?.onChange?.(e as any);
                        field.onChange(e?.map((v: any) => v.value));
                    }}
                    options={equipments}
                    placeholder={props?.placeholder}
                    disabled={props?.readOnly}
                />
            </Form.Group>
        </div>
    ) : (
        <SelectField {...props} label={lang.text(Translations.equipment.equipment)} options={equipments || []} name={props.name} />
    );
};
export default EquipmentSelectField;
