import { yupResolver } from '@hookform/resolvers/yup';
import { IIRCategoryType } from 'api/enums/IIR';
import { IIRCategoryRequest } from 'api/v2/iir/model/iirCategory.model';
import { KPITypeCreateRequest } from 'api/v2/kpi/model/kpiType.model';
import { CompanySelect } from 'components/Common';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import { InputField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IIRCategoryFormProps extends ModalHookComponentProps {
    companyIds: string[];
    type: IIRCategoryType;
    name?: string;
}
const IIRCategoryForm = ({ type, name, onClose, onCompleted, companyIds }: IIRCategoryFormProps) => {
    useEffect(() => {
        reset({ name: name, companyIds: companyIds });
    }, []);

    const { control, handleSubmit, getValues, reset } = useForm<{
        name: string;
        companyIds: string[];
    }>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
                companyIds: yup.array().required(),
            })
        ),
    });

    const onSubmit = async () => {
        var form = getValues();

        let data: IIRCategoryRequest = {
            ...form,
            type: type,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <OrganizationUnitMultiSelect
                md={12}
                name="companyIds"
                control={control}
                isSingleCompany={false}
                isSingleArea={false}
                isShowAreaSelect={false}
                requiredCompany={true}
                defaultValues={companyIds}
            />
            <InputField xs={12} md={12} name={'name'} control={control} defaultValue={name} label={lang.text(Translations.common.name)} required={true} />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default IIRCategoryForm;
