import { SelectField, SelectOption } from 'components/FormControl';
import Card from 'components/shared/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import equipmentApi from 'api/v2/equipmentApi';
import equipmentReportApi from 'api/v2/equipmentReportApi';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EquipmentReportCreateRequest, EquipmentReportGroupRequest, EquipmentReportItemRequest, EquipmentReportTemplateGroupResponse, EquipmentReportTemplateItemResponse } from 'api/models';
import { SaveButton } from 'components/Button';
import UserSelectField from 'components/Common/UserSelect';
import SelectSearchField from 'components/FormControl/SelectSearchField';
import useModalHook from 'components/ModalHook';
import { EquipmentReportType } from 'enum';
import { useNavigate } from 'react-router-dom';
import GroupContent from './group.component';
import EquipmentReportRateComponent, { EquipmentReportRatingModel } from './rating.component';

const EquipmentCreateReportPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // const [filterEquipment, setFilterEquipment] = useState<FilterEquipment>({
    //     equipmentId: null,
    //     reportType: EquipmentReportType.All,
    // });

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const [pICId, setPicId] = useState<string | null>(null);
    const [equipmentId, setEquipmentId] = useState<string | null>(null);
    const [reportType, setReportType] = useState<EquipmentReportType>(EquipmentReportType.Daily);
    const [groups, setGroups] = useState<EquipmentReportGroupRequest[] | null>(null);
    const reportTypes: SelectOption[] = useMemo(
        () => [
            { label: t(Translations.common.dailyReport), value: EquipmentReportType.Daily },
            { label: t(Translations.common.scheduleReport), value: EquipmentReportType.Scheduled },
        ],
        []
    );
    const [equipmentOptions, setEquipmentOptions] = useState<SelectOption[]>();

    const { control } = useForm<{}>();
    const onRating = async () => {
        var rs: EquipmentReportRatingModel = await openModal({
            title: t(Translations.equipment.templateCategories),
            body: <EquipmentReportRateComponent onClose={onClose} onCompleted={onCompleted} />,
        });
        if (rs) SaveChange(rs);
    };
    const searchEquipments = async (query: string) => {
        equipmentApi
            .forDropdown(
                {
                    currentPage: 1,
                    rowsPerPage: 5,
                    keySearch: query,
                },
                false
            )
            .then((res: SelectOption[]) => {
                setEquipmentOptions(res);
            })
            .catch((e) => {});
    };
    useEffect(() => {
        loadTemplate();
    }, [equipmentId, reportType]);

    const loadTemplate = async () => {
        if (equipmentId?.hasValue() && reportType && reportType != EquipmentReportType.All)
            equipmentApi
                .getTemplateForCreateReport(equipmentId, reportType)
                .then((res) => {
                    let newGroups = res?.map((g: EquipmentReportTemplateGroupResponse) => {
                        let gr: EquipmentReportGroupRequest = {
                            groupTemplateId: g.id,
                            groupTemplateName: g.groupName,
                            items:
                                g.items?.map((i: EquipmentReportTemplateItemResponse) => {
                                    let newItem: EquipmentReportItemRequest = {
                                        itemTemplateName: i.itemName,
                                        isOk: false,
                                        deadline: new Date(),
                                        itemTemplateId: i.id,
                                        description: null,
                                        solution: '',
                                    };
                                    return newItem;
                                }) ?? [],
                        };
                        return gr;
                    });
                    setGroups(newGroups);
                })
                .catch((e) => {});
    };

    const onItemChanged = (group: EquipmentReportGroupRequest, item: EquipmentReportItemRequest) => {
        // let oldItem = group.items?.f
        // let oldGroups = groups?.find((g) => g.groupTemplateId == group.groupTemplateId);
        var newGroups = groups?.map((g) => {
            var oldItem = g?.items?.find((i) => i.itemTemplateId == item.itemTemplateId);
            if (oldItem) {
                oldItem.deadline = item.deadline;
                oldItem.description = item.description;
                oldItem.isOk = item.isOk;
                oldItem.solution = item.solution;
                oldItem.itemTemplateId = item.itemTemplateId;
                oldItem.imagePathOne = item.imagePathOne;
                oldItem.imagePathTwo = item.imagePathTwo;
            }
            return g;
        });
        setGroups([...newGroups!]);
    };

    const SaveChange = (rateResult: EquipmentReportRatingModel) => {
        if (equipmentId && pICId && reportType != EquipmentReportType.All && groups) {
            let payload: EquipmentReportCreateRequest = {
                reportType: reportType,
                equipmentId: equipmentId,
                pICId: pICId,
                description: rateResult.description,
                level: rateResult.level,
                groups: groups,
            };
            equipmentReportApi
                .create(payload)
                .then((n) => {})
                .catch((n) => {});
        }
    };

    // public string Description { get; set; }
    // public Guid EquipmentId { get; set; }
    // public EquiReportLevel Level { get; set; }
    // public virtual IEnumerable<EquipmentReportGroupCreateRequest> Groups { get; set; }
    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title  ">
                                <span>Tạo báo cáo</span>{' '}
                            </h4>
                            <SaveButton type="button" onClick={onRating} />
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <SelectField
                                    label={t(Translations.common.reportType)}
                                    control={control}
                                    name="reportType"
                                    options={reportTypes}
                                    isMutiple={false}
                                    md={4}
                                    xs={12}
                                    onValueChange={(value) => {
                                        setReportType(value);
                                    }}
                                />
                                <SelectSearchField
                                    label={t(Translations.equipment.equipment)}
                                    control={control}
                                    name="equipmentId"
                                    options={equipmentOptions}
                                    onSearch={searchEquipments}
                                    md={4}
                                    xs={12}
                                    onValueChange={(value) => {
                                        setEquipmentId(value);
                                    }}
                                />
                                {reportType && reportType.toString() === EquipmentReportType.Daily.toString() && (
                                    <UserSelectField
                                        readOnly={false}
                                        label={t(Translations.equipment.reportPersonInCharge)}
                                        name="pICId"
                                        control={control}
                                        required={true}
                                        isMutiple={false}
                                        onValueChange={(n) => {
                                            setPicId(n);
                                        }}
                                        md={4}
                                        xs={12}
                                    />
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            {groups?.map((group) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <GroupContent onItemChange={onItemChanged} readonly={false} key={group.groupTemplateId} group={group} />
                                    </div>
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {Prompt}
        </Fragment>
    );
};
export default EquipmentCreateReportPage;
